// import {Link}  from "gatsby"
import React, { useEffect, useState, useRef } from "react"
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import "../Footer/Footer.scss"
import ScrollAnimation from "react-animate-on-scroll"
import { useStaticQuery, graphql } from "gatsby"
import LoadExternalScript from "../../Components/utils/load-external-script"

import ReactMarkdown from "react-markdown/with-html"
import { Link } from "@StarberryUtils"
import Breadcrumbs from "../breadcrumbs"
import { propTypes } from "react-bootstrap/esm/Image"
import NewsletterFooter from "../../Components/forms/newsletter"
import { StarLogo, TikTok } from "../icons"
import $ from "jquery"
import PopularSearch from "../popular-search-static"
import PopularSearchDynamic from "../popular-search-dynamic"
import PopularSearchDetails from "../popular-search-details"
import StickyCTAFooter from "../PropertyDetails/ButtonGroupFooter/FooterStikcyCTA"
import { useLocation, useMatch } from "@reach/router"
import PopularSearchOffice from "../popular-search-office"
import CookieConsent from "../CookieConsent/CookieConsent"
import { Helmet } from "react-helmet"

const Footer = props => {
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150)
    })
  }, [])
  const baseUrl = process.env.GATSBY_SITE_URL;
  const contact_main_match = useMatch("/contact/")
  const contact_match = useMatch("/contact/:item")
  const [iFrameHeight, setIFrameHeight] = useState('250px')
  const ref = useRef();
   
  const onLoad = () => {
    console.log('refref', ref)
    setTimeout(()=>{
      if(ref.current){
        // setIFrameHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
           }
    },500)
   
   
  };
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalConfig {
          Facebook_Link
          Footer_Menus {
            Menu_Link
            Menu_Name
          }
          Footer_Newsletter_Text
          Instagram_Link
          Linkedin_Link
          Twitter_Link
          Youtube_Link
          Footer_Logos{
            Name
            URL
            Logo {
              url
              name
            }
          }
        }
      }
    }
  `)
  var footer_data = data.glstrapi.globalConfig
  useEffect(() => {
    // if(props.searchtype === "details"){
    //   $(".open-popular-search").toggleClass("searchopen")
    //   $(".popular-searchlist").slideToggle()
    // }
    $(".popular-searchlist ul li a").addClass("btn-text-link")
    $(".open-popular-search").click(function (event) {
      $(".open-popular-search").toggleClass("searchopen")
      $(".popular-searchlist").slideToggle()
    })
    //
    $(".footer_scroll_top").click(function () {
      $("html, body").scrollTop(0)
    })
    //
    const timer = setTimeout(() => {
      onLoad();
    }, 1000);
    return () => clearTimeout(timer);
  }, [])
  return (
    <>
      <footer className="footer">
        <CookieConsent/>
        <Container>
          {props.Title !== "Home" && (
            <div className="animated">
              <Row>
                <Col>
                  <div className="breadcrumb-footer">
                    <Breadcrumbs
                      page="news"
                      property_type={props.property_type}
                      property_display_address={props.property_display_address}
                      resultlabel={props.resultlabel}
                      baseurl={props.baseurl}
                      alias={props.alias}
                      catalias={props.catalias}
                      catlabel={props.catlabel}
                      menulabel={props.menulabel}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <Row>
            <Col xl={6} className="footer-valuation-steps-hide">
              <div className="animated">
                <div className="footer-form-sec">
                  {/* <div className="footer-top-content">
                    <ReactMarkdown
                      source={footer_data.Footer_Newsletter_Text}
                      escapeHtml={false}
                    />
                  </div> */}
                  <div className="newsletter-form-footer">
                    {/* <LoadExternalScript
                      src="https://locationlocation34980.activehosted.com/f/embed.php?id=12"
                      // type="text/javascript"
                      // charset="utf-8"
                      defer="true"
                      async="true"
                    />
                    <div class="_form_12"></div> */}

                    {/* <NewsletterFooter />    */}
                    <div className="heading">
                    <h3>Word on the street.</h3>
                    <p>Stay in the loop with our weekly newsletter.</p>
                    </div>
                
                    <iframe
                      src={`https://send.integratedinterest.com/users/subscribe/js_id/7n1s/id/3/`} id="integratedinterest"
                      ref={ref}
                      onLoad={onLoad}
                      height={"400px"}
                      scrolling="no"
                      frameborder="0" 
                      style={{
                        width: "100%",
                        overflow: "auto" 
                    }}
                    ></iframe> 
                   
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={{ span: 4, offset: 2 }} className="valuation-steps-col">
              <div className="animated">
                <div className="footer-social-icons">
                  <ul>
                    <li>
                      <a href={`${footer_data.Facebook_Link}/`} target="_blank">
                        <i className="icon-social-foot icon-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="ft-sco"
                        href={`${footer_data.Twitter_Link}/`}
                        target="_blank"
                      >
                        <i className="icon-social-foot icon-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={`${footer_data.Instagram_Link}`} target="_blank">
                        <i className="icon-social-foot icon-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href={`${footer_data.Youtube_Link}/`} target="_blank">
                        <i className="icon-social-foot icon-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href={`${footer_data.Linkedin_Link}`} target="_blank">
                        <i className="icon-social-foot icon-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/@locationlocationlondon"
                        target="_blank"
                      >
                        <i className="icon-social-foot icon-tiktok"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="valuation-quick-links">
                  <div className="quick-links">
                    <ul>
                      {footer_data.Footer_Menus.map(footermenu => {
                        return (
                          <>
                            <li>
                              <Link
                                to={footermenu.Menu_Link}
                                className="btn-text-link"
                              >
                                {footermenu.Menu_Name}
                              </Link>
                              {/* {footermenu.Menu_Name != "Site Map" && ( */}
                                <span className="menusep"></span>
                              {/* )} */}
                            </li>
                          </>
                        )
                      })}
                      <li>
                      <a href="javascript:void(0);" id="open_preferences_center" className="btn-text-link">Update Cookies Preferences</a>
                      </li>
                     
                    </ul>
                  </div>
                  <p className="copyright">
                    <span className="line1-copyright">
                      &copy;{new Date().getFullYear()} Location Location. All
                      rights reserved.
                    </span>{" "}
                    <span className="line2-copyright">
                      Site by{" "}
                      <a
                        href="http://starberry.tv/"
                        target="_blank"
                        className="btn-text-link"
                      >
                        <StarLogo />
                      </a>
                    </span>
                  </p>
                </div>
                <div className="footer-logos">
                  <ul>
                  {footer_data.Footer_Logos.map(footerlogo => {
                        return (
                          <>
                            <li>
                              <a href={footerlogo?.URL} target="blank">
                                <img src={footerlogo?.Logo[0]?.url} />
                              </a>
                            </li>
                          </>
                        )
                  })}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <section className="popular-searches">
          <Container>
            <a href="javascript:;" className="open-popular-search">
              <span>Popular Searches</span>
              <i className="icon-plus-no-round"></i>{" "}
              <i className="pop-minus">&#8212;</i>
            </a>
            <div className="popular-searchlist">
              {props.searchtype === "sales" ||
              props.searchtype === "rentals" ? (
                <PopularSearchDynamic
                  searchtype={props.searchtype}
                  searchBedroomfield={props.searchBedroomfield}
                  searchPtype={props.searchPtype}
                  Searcharea={props.Searcharea}
                />
              ) : props.searchtype === "details" ? (
                <PopularSearchDetails
                  searchtype={props.property_type}
                  property_building={props.property_building}
                  property_address={props.property_address}
                />
              ) : props.searchtype === "office-details" ? (
                <PopularSearchOffice officename={props.officename} />
              ) : (
                <PopularSearch popularSearch={props.popularSearch} />
              )}
            </div>
          </Container>
        </section>
        <Helmet>
          <script type="text/javascript">{`_linkedin_partner_id = "4241402";
           window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
           window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
          </script>

          <script type="text/javascript">{`(function(l) {if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";
          b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`}
          </script>

          <noscript>
          {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4241402&fmt=gif" />`}
          </noscript>
        </Helmet>
      </footer>
      {props.searchtype !== "details" &&
      contact_match == null &&
      contact_main_match == null &&
      scroll &&
      !props.fullpage &&
      !props.isServicePage ? (
        <StickyCTAFooter />
      ) : (
        ""
      )}
    </>
  )
}
export default Footer
